import React, { useState } from "react"
import Header from "../components/Header"
import "../styles/app.css"
import Helmet from "react-helmet"
import Favicon from "../imgs/favicon.png"
import { Link } from "gatsby"
import ImgTopSofortCasinos from "../imgs/Top-Sofort-Online-Casinos.jpg"
import ImgCashOuts from "../imgs/sofort-cash-outs-up-to-72-hours.jpg"
import ImgMakingDeposit from "../imgs/making-a-deposit-with-a-sofort.png"
import ImgFAQ from "../imgs/frequently-asked-questions–online-banking.png"
import Feature from "../components/Feature"
import IconMoneySaving from "../imgs/icon-money-saving.png"
import IconOnlineGlobe from "../imgs/icon-online-globe.png"
import IconOnlinePayment from "../imgs/icon-online-payment.png"
import BgImg from "../imgs/sofort-online-payment-provider.jpg"
import ImgKlarna from "../imgs/klarna-bought-payment-rival.png"
import ImgFastPaymentService from "../imgs/fast-payment-service.png"
import ImgEn from "../imgs/en.png"
import ImgDe from "../imgs/de.png"

export default function HomeDe() {
  const [isHamburgerClicked, setIsHamburgerClicked] = useState(false)

  function handleNavBtnClick() {
    setIsHamburgerClicked(prevState => {
      return !prevState
    })
  }

  const mobileNavBarStyle = {
    left: `${isHamburgerClicked ? "10px" : "10000px"}`,
  }

  return (
    <div className="homepage">
      <Helmet>
        <title>
          Sofort Casinos – Online Casinos, die Sofort 2022 akzeptieren
        </title>
        <meta
          name="title"
          content="Sofort Casinos – Online Casinos, die Sofort 2022 akzeptieren"
        />
        <meta
          name="description"
          content="Sofort Online Casinos – Sehen Sie sich Online-Casinos an, die Sofort-Zahlungsmethode im Jahr 2022 sowohl für Einzahlungen als auch für Auszahlungen akzeptieren. Erfahren Sie mehr über Sofort."
        />
        <link rel="shortcut icon" type="image/x-icon" href={Favicon} />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <link
          rel="alternate"
          href="https://www.getsofort.com/de"
          hreflang="de"
        />
        <link rel="alternate" href="https://www.getsofort.com" hreflang="en" />
        <link
          rel="alternate"
          href="https://www.getsofort.com"
          hreflang="x-default"
        />
      </Helmet>
      <Header lang="de" />
      <div className="navigation">
        <div
          role="presentation"
          className="hamburger-icon"
          onClick={handleNavBtnClick}
          onKeyDown={handleNavBtnClick}
        >
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
          <div className="hamburger-icon-line"></div>
        </div>
        <ul style={mobileNavBarStyle}>
          <li>
            <Link to="/de">Home</Link>
          </li>
          <li>
            <Link to="/de/#about-sofort">Über Sofort</Link>
          </li>
          <li>
            <Link to="/de/#online-casinos">Online Casinos</Link>
          </li>
          <li>
            <Link to="/de/#deposit">Deposit</Link>
          </li>
          <li>
            <Link to="/de/#cashouts">Cashouts</Link>
          </li>
          <li>
            <Link to="/de/#faq">FAQ</Link>
          </li>
          <li>
            <Link to="/de/blog">Blog</Link>
          </li>
        </ul>
      </div>
      <div className="langs">
        <Link to="/">
          <img src={ImgEn} alt="English" />
        </Link>
        <Link to="/de">
          <img src={ImgDe} alt="Deutsch" />
        </Link>
      </div>
      <div className="home" id="home">
        <div className="home-top">
          <img
            src={BgImg}
            alt="sofort-online-payment-provider"
            className="home-image"
          />
          <h1
            className="centered-in-image"
            style={{ textTransform: "uppercase" }}
          >
            Sofort Online Casinos
          </h1>
        </div>
        <div className="features">
          <Feature
            img={IconMoneySaving}
            alt="Money Saving"
            title="Schnell und einfach"
            content="Mit Sofort Banking können Sie jetzt in Sekundenschnelle Einzahlungen in Ihr Lieblings-Online-Casino tätigen."
          />
          <Feature
            img={IconOnlineGlobe}
            alt="Online Global"
            title="Keine zusätzlichen Schritte"
            content="Eine Registrierung bei Sofort Banking ist nicht erforderlich. Alles, was Sie brauchen, ist Ihr tägliches Online-Banking-Konto und schon kann es losgehen."
          />
          <Feature
            img={IconOnlinePayment}
            alt="Online Payment"
            title="Keine Daten, keine Probleme"
            content="Die Sicherheit bei Sofort Überweisung ist erstklassig. Da keine Ihrer privaten Daten jemals auf Servern gespeichert werden, besteht kein tatsächliches Risiko einer Datenpanne."
          />
        </div>
        <p>
          Sofort ist das coole Kid auf dem digitalen Block, das in der Lage ist,
          die traditionelle, langsam abwickelnde Online-Zahlungsbranche zu
          stören. Mit Sofortüberweisung können Sie jetzt direkte Überweisungen
          ohne das übliche zeitraubende Ausfüllen von Formularen und lästige
          Verzögerungen tätigen. Mit dieser innovativen Zahlungsmethode werden
          Ihre Banküberweisungen im Handumdrehen in einer sicheren Umgebung
          ausgeführt.
        </p>
        <p>
          Schauen Sie sich unbedingt die besten Sofort-Casinos an, die alle
          bereit sind, die Zukunft zu akzeptieren, und lassen Sie Ihre
          Lieblings-Casinospiele im Handumdrehen spielen. Erfahren Sie in den
          folgenden Zeilen mehr über die Sofort-Zahlungsmethode und
          Online-Casinos, die Sofort im Jahr 2022 akzeptieren.
        </p>
      </div>
      <div className="about-sofort section" id="about-sofort">
        <h2>Über die Sofort-Zahlungsmethode</h2>
        <p>
          Anfang 2006 zunächst als Filiale eines deutschen
          Aktienhandelsunternehmens gegründet, gewann Sofort Überweisung in
          Deutschland an Fahrt und wurde bald zu einer der beliebtesten
          Online-Zahlungsarten der Deutschen.
        </p>
        <img
          src={ImgTopSofortCasinos}
          alt="Top Sofort Online Casinos"
          className="content-img"
        />
        <p>
          Im Jahr 2014 wurde Sofort vom schwedischen Finanzdienstleister Klarna
          Bank AB übernommen, wodurch ein reibungsloser Übergang von einem
          lokalen deutschen Zahlungsmittel zu einem beliebten, europaweit
          verfügbaren Finanzdienstleistungsunternehmen vollzogen wurde.
        </p>
        <p>
          Ab 2020 wird Klarna mit über 2 Milliarden EUR bewertet. Erst letztes
          Jahr hat das Unternehmen mehr als 60 Millionen Benutzern dabei
          geholfen, sich mit etwa 90.000 Online-Händlern aus der ganzen Welt,
          einschließlich den USA, zu verbinden.
        </p>
      </div>
      <div className="online-casinos section" id="online-casinos">
        <h2 className="content-title">
          Online-Casinos, die Sofort im Jahr 2022 akzeptieren
        </h2>
        <p className="content-text">
          Die Online-Casinos konnten sich diese Gelegenheit nicht entgehen
          lassen, also begannen sie, Sofort als erste Einzahlungs- und später
          als Auszahlungsoption zu implementieren. Und warum nicht? Da immer
          mehr Online-Käufer Sofortüberweisung als Standardzahlungsmethode
          verwenden, fordern die Online-Casino-Spieler jetzt die Implementierung
          von Sofort Überweisung für so viele Casino-Websites wie möglich an.
        </p>
        <img
          src={ImgKlarna}
          alt="Klarna kaufte Zahlungsrivale"
          className="content-img"
        />
        <p className="content-text">
          Zum Glück für Sie haben die meisten Online-Casinos das Feedback
          erhalten und sind nun dabei, Sofort ihren Kunden vorzustellen, falls
          sie dies noch nicht getan haben. Wenn Sie uns nicht glauben, sehen Sie
          sich einfach unsere besten Sofort-Online-Casinos an.
        </p>
        <p className="content-text">
          Ist Ihr Lieblingscasino NICHT in unserer Liste? Es wird noch früh
          genug sein, aber wie wäre es bis dahin, einen anderen Anbieter
          auszuprobieren, der Sofort bereit ist?
        </p>
      </div>
      <div className="deposit section" id="deposit">
        <h2>Deposit mit Sofort</h2>
        <p>Der Vorgang einer Einzahlung mit Sofort war noch nie so einfach.</p>
        <img
          src={ImgMakingDeposit}
          alt="Eine Einzahlung mit Sofort tätigen"
          className="content-img"
        />
        <p>
          Melden Sie sich einfach bei Ihrem bevorzugten Online-Casino an, das
          Sofort akzeptiert, klicken Sie auf Einzahlen, geben Sie den
          gewünschten Betrag ein, wählen Sie Sofortüberweisung als
          Zahlungsoption und Sie werden im Handumdrehen zu einer sicheren
          Online-Umgebung weitergeleitet, in der Sie sich zuerst anmelden müssen
          Wählen Sie Ihr Land und Ihre bevorzugte Bank. Stellen Sie sicher, dass
          Sie ein Konto bei der ausgewählten Bank haben, das Online-Banking
          aktiviert ist und vor allem das erforderliche Guthaben auf diesem
          Bankkonto vorhanden ist. Nach der Auswahl werden Sie aufgefordert,
          Ihre Online-Banking-Daten einzugeben, ähnlich wie Sie sich
          normalerweise in Ihr Online-Banking-Konto einloggen.
        </p>
        <p>
          Da sich Sofort um alle nörgelnden Zahlungsdetails kümmert (wie viel?
          An wen?), ist der letzte Schritt so einfach wie möglich: Klicken Sie
          einfach auf die Schaltfläche Bestätigen und voila! In Sekundenschnelle
          haben Sie echtes Geld auf Ihrem Casino-Konto und sind mehr als bereit,
          an die Casino-Tische zu gehen.
        </p>
      </div>
      <div className="cashouts section" id="cashouts">
        <h2>Auszahlungen</h2>
        <p className="emphasized">
          Wenn Sie dachten, dass Einzahlungen mit Sofort Banking kinderleicht
          sind, warten Sie, bis Sie die Auszahlungen erleben.
        </p>
        <p>
          Da Sie eine Einzahlung mit Sofort getätigt haben, werden alle
          Bankdaten sicher gespeichert, sodass Sie Ihre Gewinne noch einfacher
          abheben können, als Sie zuvor eingezahlt haben. Noch einmal, wählen
          Sie einfach Sofortüberweisung als Ihre Auszahlungsoption, folgen Sie
          den einfachen Schritten, klicken Sie auf ein paar Schaltflächen und
          Ihre Gewinne sind auf dem Weg zu Ihrem Bankkonto. Das Abtippen der
          IBAN oder anderer Bankdaten entfällt.
        </p>
        <img
          src={ImgCashOuts}
          alt="Sofortige Auszahlungen bis zu 72 Stunden"
          className="content-img"
        />
        <p>
          Bemerkenswert ist jedoch, dass Auszahlungen nicht blitzschnell sind
          wie die Einzahlungen. Abhängig von Ihrem Online-Casino und Ihrer Bank
          müssen Sie in der Regel bis zu 72 Stunden (oder 3 Werktage) warten,
          bis Sie Ihre Gewinne vollständig nutzen können.
        </p>
      </div>
      <div className="faq section" id="faq">
        <h2>FAQ (Häufig gestellte Fragen)</h2>
        <p className="emphasized">
          Haben Sie Fragen zu Sofortüberweisung? Wir werden unser Bestes tun, um
          sie im FAQ-Bereich unten zu beantworten.
        </p>
        <img
          src={ImgFAQ}
          alt="Häufig gestellte Fragen – Online-Banking"
          className="content-img"
        />
        <h3>
          Warum sollte ich Sofortüberweisung anstelle eines E-Wallets verwenden?
        </h3>
        <p>
          Der Hauptvorteil der Verwendung von Sofort im Vergleich zu einem
          herkömmlichen E-Wallet ist die Bequemlichkeit. Mit Sofort müssen Sie
          kein Konto erstellen und wertvolle Zeit damit verbringen, sich an die
          Benutzeroberfläche des E-Wallets zu gewöhnen. Alles, was Sie tun
          müssen, ist, das vertraute Online-Banking-Konto zu verwenden, auf
          Bestätigen zu klicken und einige Sekunden zu warten, um Ihr
          Online-Casino-Konto aufzuladen.
        </p>
        <h3>Welche Gebühren fallen an, wenn ich Sofortüberweisung verwende?</h3>
        <p>
          Für jede Überweisung berechnet Sofort dem Verkäufer standardmäßig 2 %
          plus 0,25 EUR für jede Art von digitalen Gütern. Da der Verkäufer,
          auch bekannt als das Online-Casino, zusätzliche Gebühren zusätzlich zu
          diesen Standardgebühren erheben kann, kann und wird Ihre
          Einzahlungs-/Auszahlungserfahrung je nach Casino variieren. Weitere
          Informationen finden Sie auf Ihrer Online-Casino-Website.
        </p>
        <h3>Ist Sofortüberweisung sicher?</h3>
        <p>
          Es ist wirklich. Sofort verwendet ein Bestätigungscodesystem, das
          Schutz vor Missbrauch bietet. Darüber hinaus werden die von Ihnen
          eingegebenen sensiblen Online-Banking-Daten (wie die PIN) nicht
          gespeichert und sind weder für Sofort noch für das Online-Casino
          zugänglich.
        </p>
        <h3>Kann ich Sofort auch ohne Online-Banking-Konto nutzen?</h3>
        <p>
          Leider können Sie nicht. Um Sofort nutzen zu können, benötigen Sie
          eine onlinefähige Kontoführung, heute gemeinhin als Online-Banking
          bekannt. Weitere Informationen erhalten Sie bei Ihrer Bank in Ihrer
          Nähe.
        </p>
      </div>
      <div className="summary">
        <h2>Zusammenfassung</h2>
        <p>
          Sofortüberweisung ist ein schneller Online-Zahlungsservice, der als
          Vermittler zwischen Ihnen als Kunde und Ihrer Bank fungiert. Es ist
          nicht erforderlich, ein Konto zu erstellen; Alles, was Sie brauchen,
          ist ein Bankkonto mit Online-Verwaltungsfunktionen und ein
          vertrauenswürdiges Online-Casino, das Sofort sowohl als Einzahlungs-
          als auch als Auszahlungsoption akzeptiert.
        </p>
        <img
          src={ImgFastPaymentService}
          alt="Schneller Zahlungsservice"
          className="content-img"
        />
        <p className="emphasized">
          Wenn Sie nicht wissen, wo Sie mit der Suche nach solchen
          Spielanbietern beginnen sollen, sehen Sie sich unsere
          Sofort-Casino-Empfehlungen an.
        </p>
      </div>
      <div className="footer">
        © Copyright 2022. Alle Rechte vorbehalten von GetSofort
      </div>
    </div>
  )
}
