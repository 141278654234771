import React from "react"

export default function Feature({ img, alt, title, content }) {
  return (
    <div className="feature rounded-md">
      <img className="feature-img" src={img} alt={alt} />
      <p className="feature-title">{title}</p>
      <p className="feature-content">{content}</p>
    </div>
  )
}
